/* You can add global styles to this file, and also import other style files */

/** This is a HACK! Not sure which toastr to be using. We're using https://www.npmjs.com/package/ngx-toastr for now **/
#toast-container>.toast-error:before {
    content: ""!important;
}
#toast-container > .toast-error:before {
content: ""!important;
}
#toast-container > .toast-info:before {
content: ""!important;
}
#toast-container > .toast-success:before {
content: ""!important;
}

/** This style is needed to hide dropdown arrow near profile icon in the header **/
.sk-user-account .sk-dropdown-custom.dropdown-toggle::after {
    display: none;
}